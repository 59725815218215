import React from "react";
import ErrorImg from "../../../assets/svg/ExternalPages/Error.png";
import "./page-not-found.css";
import { Helmet } from "react-helmet-async";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found - Quantum</title>
        <meta
          name="description"
          content="Oops! The page you are looking for does not exist. Check the URL or return to the homepage."
        />
        <meta
          name="keywords"
          content="404 Error, Page Not Found, Broken Link, Quantum Error Page, Missing Page"
        />
      </Helmet>
      <div className="hero-page-main">
        <div className="hero-sub-details container">
          <img src={ErrorImg} alt="page_not_found_image" />
          <p className="heading">Uh-oh, we couldn't find that code!</p>
          <p className="sub-heading">
            Whoops! Something’s broken, but we’re on it! Head back to the <br />
            homepage where everything’s running smoothly.
          </p>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
