import "./ProductionProcess.css";
import HeroComponent from "../../../components/Hero/HeroComponent.jsx";
import ProductionProcessSvg from "../../../assets/svg/ProductSvg/ProductionPng.png";
import productionLoginImg from "../../../assets/svg/ProductSvg/ProductionLogin.svg";
import feature1Svg from "../../../assets/svg/ProductionFeatureSvg/F1.svg";
import feature2Svg from "../../../assets/svg/ProductionFeatureSvg/F2.svg";
import feature3Svg from "../../../assets/svg/ProductionFeatureSvg/F3.svg";
import feature4Svg from "../../../assets/svg/ProductionFeatureSvg/F4.svg";
import feature5Svg from "../../../assets/svg/ProductionFeatureSvg/F5.svg";
import feature6Svg from "../../../assets/svg/ProductionFeatureSvg/F6.svg";
import feature7Svg from "../../../assets/svg/ProductionFeatureSvg/F7.svg";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent.jsx";
import BBIC1 from "../../../assets/svg/ProductionFeatureSvg/BBI1.svg";
import BBIC2 from "../../../assets/svg/ProductionFeatureSvg/BBI2.svg";
import BBIC3 from "../../../assets/svg/ProductionFeatureSvg/BBI3.svg";
import Solution from "../../../components/Platy/Solution.jsx";
import ProductFeature from "../../../components/ProductFeature/ProductFeature.jsx";
import ProductDescription from "../../../components/ProductDescription/ProductDescription.jsx";
import { Helmet } from "react-helmet-async";

const productFeatureData = [
  {
    image: feature1Svg,
    cardHeader: "SAP B1 Integration",
    cardDesc:
      "Automatically submits approved entries to SAP B1 and updates records in real-time, eliminating manual data entry and ensuring accuracy.",
  },

  {
    image: feature2Svg,
    cardHeader: "Step-by-Step Verification",
    cardDesc:
      "Each stage—issuing, receiving, and closing—requires approval to ensure accuracy and quality. This verification process keeps operations smooth and error-free.",
  },
  {
    image: feature3Svg,
    cardHeader: "Report Analysis",
    cardDesc:
      "Easily track and manage all production orders with real-time status updates and flexible export options for detailed reporting.",
  },
  {
    image: feature4Svg,
    cardHeader: "User Management",
    cardDesc:
      "Create multiple users with customizable roles and authorizations. Manage permissions and access levels to ensure secure and efficient platform use.",
  },
];
const productionFeatureSamll = [
  {
    image: feature5Svg,
    cardHeader: "Clarifying Decisions",
    cardDesc:
      "Add reasons for every approval or rejection to ensure transparency.",
  },
  {
    image: feature6Svg,
    cardHeader: "Live Support",
    cardDesc:
      "Provides real-time help for queries or issues, ensuring prompt resolutions.",
  },
  {
    image: feature7Svg,
    cardHeader: "Status Management",
    cardDesc:
      "Tracks entries as Pending, Approved, or Rejected, with required remark for rejections.",
  },
];
const bookNowData = [
  {
    image: BBIC1,
    text1: "3x",
    text2: "Cost Saving",
  },
  {
    image: BBIC2,
    text1: "100%",
    text2: "Free Trial",
  },
  {
    image: BBIC3,
    text1: "3x",
    text2: "Clients",
  },
];

const ProductionProcess = () => {
  return (
    <>
      <Helmet>
        <title>
          Streamline Your Production Process with SAP Integration - Quantum
        </title>
        <meta
          name="description"
          content="Optimize your production workflow with our SAP-integrated web platform. Ensure accurate verification, seamless order management, and real-time tracking for efficient production processes."
        />
        <meta
          name="keywords"
          content="Production Management, SAP B1 Integration, Order Verification, Production Workflow, Status Tracking, Business Automation, Quantum Production Module"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="Streamline Your Production Process with SAP Integration - Quantum"
        />
        <meta
          property="og:description"
          content="Optimize your production workflow with our SAP-integrated web platform. Ensure accurate verification, seamless order management, and real-time tracking for efficient production processes."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/product/production-process/assets/ProductionPng.png"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/product/production-process"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Streamline Your Production Process with SAP Integration - Quantum"
        />
        <meta
          name="twitter:description"
          content="Optimize your production workflow with our SAP-integrated web platform. Ensure accurate verification, seamless order management, and real-time tracking for efficient production processes."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/product/production-process/assets/ProductionPng.png"
        />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#DFEFFFCC"}
          secondaryGradiant={"#F0F7FF00"}
          heroHeader={`Seamless Production Process Powered by SAP`}
          heroDetail={
            "With our web product,effortlessly create production orders that undergo thorough verification at every step. From issuing to receiving and closing, each process is carefully checked for accuracy, ensuring smooth and reliable operations."
          }
          heroImage={ProductionProcessSvg}
        />
        <Solution />
        <ProductDescription
          ProductName={"Production Mgt"}
          TextColor={"#0057A1"}
          Discription={
            "Easily create and oversee production orders with our web platform. Each phase—verification, issuing, and receiving—guarantees precision, letting you finalize orders with assurance."
          }
          image={productionLoginImg}
        />
        <ProductFeature
          image={feature1Svg}
          productFeatureData={productFeatureData}
          productionFeatureSamll={productionFeatureSamll}
        />
        <BooknowComponent
          headingText={"Grows with your business"}
          primaryGradiant={"#0057A1CC"}
          secondaryGradiant={"#0057A1"}
          bookNowData={bookNowData}
          backgroundColor={"#DFEFFF"}
          backGroundGradient={
            "linear-gradient(90deg, rgba(0, 87, 161, 0.8) 0%, rgba(0, 87, 161, 1) 100%)"
          }
          productname={"Web Production Module"}
        />
      </div>
    </>
  );
};

export default ProductionProcess;
