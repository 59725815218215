import React from "react";
import HeroComponent from "../../../components/Hero/HeroComponent";
import HeroPng from "../../../assets/svg/ProductSvg/PaymentIntegration.png";
import { FeatureComponent } from "../../../components/FeatureComponent/FeatureComponent";
import { ProductFeatureCard } from "../../../components/ProductFeatureCard/ProductFeatureCard";
import F1 from "../../../assets/svg/PaymentIntegration/F1.svg";
import F2 from "../../../assets/svg/PaymentIntegration/F2.svg";
import F3 from "../../../assets/svg/PaymentIntegration/F3.svg";
import Solution from "../../../components/Platy/Solution";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent";
import { Helmet } from "react-helmet-async";

const PaymentIntegration = () => {
  const featureComponentData = [
    {
      headerText: "Transaction Sync",
      detailText:
        "Transactions from SAP are synced with the payment integration software.",
    },
    {
      headerText: "Approval Process",
      detailText:
        "Users approve or reject transactions, sending approved data securely to HDFC.",
    },
    {
      headerText: "Response from HDFC",
      detailText:
        "HDFC confirms with a UTR number or returns an error for corrections.",
    },
  ];

  const featureCardData = [
    {
      image: F1,
      headerText: "Data Encrypted ",
      detailText:
        "Your information is securely encrypted, ensuring confidentiality.",
    },
    {
      image: F2,
      headerText: "Step By Step Verification",
      detailText:
        "You cannot proceed without a decision, and remarks are required for any rejection.",
    },
    {
      image: F3,
      headerText: "SAP B1 Integration",
      detailText:
        "Syncs entries to SAP B1 in real-time, ensuring accurate data.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>HDFC Payment Integration - Quantum</title>
        <meta
          name="description"
          content="Integrate seamlessly with HDFC Bank for secure and efficient payment processing. Sync transactions with SAP B1 and ensure safe financial management."
        />
        <meta
          name="keywords"
          content="Payment Integration, HDFC Bank Payments, Secure Transactions, SAP B1 Payment Sync, Financial Management, Encrypted Payments, Quantum Payment Processing"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="HDFC Payment Integration - Quantum"
        />
        <meta
          property="og:description"
          content="Integrate seamlessly with HDFC Bank for secure and efficient payment processing. Sync transactions with SAP B1 and ensure safe financial management."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/product/payment-integration/assets/PaymentIntegration.png"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/product/payment-integration"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="HDFC Payment Integration - Quantum"
        />
        <meta
          name="twitter:description"
          content="Integrate seamlessly with HDFC Bank for secure and efficient payment processing. Sync transactions with SAP B1 and ensure safe financial management."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/product/payment-integrationassets/PaymentIntegration.png"
        />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#DFEFFF99"}
          secondaryGradiant={"#F0F9FE00"}
          heroHeader={"Secure and Efficient HDFC Payment Integration"}
          heroDetail={
            "Integrate seamlessly with HDFC for smooth payment processing, ensuring secure transactions and efficient financial management. This integration streamlines your payment workflows, allowing for quick and easy processing of transactions"
          }
          heroImage={HeroPng}
        />
        <FeatureComponent
          featureComponentData={featureComponentData}
          titleText="Seamless Transaction"
        />
        <ProductFeatureCard featureCardData={featureCardData} />
        <Solution />
        <BooknowComponent
          headingText={"Ensuring Safety in Every Transaction"}
          bookNowData={[]}
          backGroundGradient={"linear-gradient(90deg, #0057A1CC,#0057A1 "}
          backgroundColor={"#DFEFFF"}
          productname={"Payment Integration"}
        />
      </div>
    </>
  );
};
export default PaymentIntegration;
