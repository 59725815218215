import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import { OurProjectComponent } from "../../../components/Services/our-projects/OurProjectComponent";
import {
  CRM,
  Figma,
  Illustrator,
  Itterative,
  Photoshop,
  Signakshar,
  Vendor,
  Wireframes,
  XD,
} from "../../../assets";
import { ToolsWeUse } from "../../../components/Services/tools-we-use/ToolsWeUse";
import { SapVeriticalCorosal } from "../../../components/sap-pages-components/sap-vertical-corosal/SapVerticalCorosal";
import { ServiceFeatureComponent } from "../../../components/Services/service-feature/ServiceFeatureComponent";
import { Helmet } from "react-helmet-async";
const BOOK_NOW_LINK = process.env.REACT_APP_BOOK_NOW_LINK;

const featureComponentData = [
  {
    headerText: "Brand Identity",
    detailText:
      "A well-designed product can help to create a strong brand identity and encourage customers to keep using the product",
  },
  {
    headerText: "User Satisfaction",
    detailText:
      "Product is easy to use and navigate, which leads to a better user experience and will recommend it to others.",
  },
  {
    headerText: "Boost Sales & Revenue",
    detailText:
      "The user-friendly product can make it easier for customers to find and purchase the products and services they need.",
  },
];

const ourProjectComponentData = [
  {
    headerImg: Signakshar,
    headerText: "E-Signakshar",
    detailText:
      "Our e-signature software streamlines PDF signing, enhancing productivity and collaboration.",
  },
  {
    headerImg: CRM,
    headerText: "Customer Relationship Mgt.",
    detailText:
      "Customer Relationship Management is a system businesses use to manage customer interactions, streamline processes.",
  },
  {
    headerImg: Vendor,
    headerText: "Vendor & Customer Mgt.",
    detailText:
      "An SAP Business One integrated solution for managing vendor and customer relationships.",
  },
];
const toolsComponentData = [
  {
    headerImg: Figma,
  },
  {
    headerImg: XD,
  },
  {
    headerImg: Photoshop,
  },
  {
    headerImg: Illustrator,
  },
];
const sections = [
  {
    id: "top",
    title: "Wireframes",
    description:
      "We create IT solutions that boost efficiency and business growth.",
  },
  {
    id: "bottom",
    title: "Iterative Process",
    description: "We harness technology to drive your success and growth.",
  },
];
const UXUIDesign = () => {
  return (
    <>
      <Helmet>
        <title>
          UX/UI Design Services - User-Centric & Impactful Designs | Quantum IT
          Solution
        </title>
        <meta
          name="description"
          content="Enhance user experiences with our innovative UX/UI design services. We craft engaging, user-friendly interfaces that drive conversions and business success."
        />
        <meta
          name="keywords"
          content="UX/UI Design, User Experience, Interface Design, Wireframe, Iterative Design, Mobile App Design, Web Design, Figma, Adobe XD, Quantum IT"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="UX/UI Design Services - User-Centric & Impactful Designs | Quantum IT Solution"
        />
        <meta
          property="og:description"
          content="Enhance user experiences with our innovative UX/UI design services. We craft engaging, user-friendly interfaces that drive conversions and business success."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/services/ux-ui-design/assets/svg/Services/ux-ui-design-hero.png"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/services/ux-ui-design"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="UX/UI Design Services - User-Centric & Impactful Designs | Quantum IT Solution"
        />
        <meta
          name="twitter:description"
          content="Enhance user experiences with our innovative UX/UI design services. We craft engaging, user-friendly interfaces that drive conversions and business success."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/services/ux-ui-design/assets/svg/Services/ux-ui-design-hero.png"
        />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div className="cloud-infra-main">
        <CloudHeroSection
          capText={"Aka UX/UI Magician!"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="Innovative UX/UI Design for Meaningful and Impactful Interactions"
          detailsText="We create high-quality, user-friendly mobile applications tailored to meet the needs of the end user. Our experts specialize in developing both native and hybrid apps to   client requirements. Every app we build is designed to stand out and succeed in the market. With a team of skilled developers and designers, we ensure an exceptional user experience by leveraging the latest technologies and best practices to deliver secure, scalable solutions."
        />
        <SapVeriticalCorosal
          sections={sections}
          V1={Wireframes}
          V2={Itterative}
          headerText={"Our Process"}
          detailText={
            "Driven by our passion for design, we create interconnected and seamless user experiences."
          }
        />
        <ServiceFeatureComponent featureComponentData={featureComponentData} />
        <OurProjectComponent
          ourProjectComponentData={ourProjectComponentData}
          titleText={"Our Projects"}
        />
        <ToolsWeUse
          toolsComponentData={toolsComponentData}
          titleText={"Tools we use"}
        />

        <section className="section-connect container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Let’s do business together, benefiting both.
              </div>
              <div className="connect-info-text2">
                Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
              </div>
            </div>
            <div className="connect-info-btn">
              <a href={BOOK_NOW_LINK} target="_blank">
                <button>Heya, Connect?</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UXUIDesign;
