import React from "react";
import { CareerMain } from "../../../assets";
import { CurrentOpenings } from "../../../components/Career/current-openings";
import { Helmet } from "react-helmet-async";
import "./career.css";

const currentComponentData = [
  {
    openingName: "SAP Functional Consultant",
    openingExp: "1-2 Exp.",
    applyNow: "Apply Now",
  },
  {
    openingName: "SAP Technical Consultant",
    openingExp: "1-2 Exp.",
    applyNow: "Apply Now",
  },
  {
    openingName: "Product Lead",
    openingExp: "1-2 Exp.",
    applyNow: "Apply Now",
  },
  {
    openingName: "Full Stack Developer",
    openingExp: "1-2 Exp.",
    applyNow: "Apply Now",
  },
];

const CareerPage = () => {
  return (
    <>
      <Helmet>
        <title>Careers at Quantum - Join Our Team</title>
        <meta
          name="description"
          content="Explore career opportunities at Quantum. Discover our culture, values, and how you can be a part of our innovative team."
        />
        <meta
          name="keywords"
          content="Quantum Careers, Join Our Team, Job Openings, Job Opportunities, Company Culture, Employment"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="Careers at Quantum - Join Our Team"
        />
        <meta
          property="og:description"
          content="Explore career opportunities at Quantum. Discover our culture, values, and how you can be a part of our innovative team."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/company/career/assets/career-main.jpg"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/company/career"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Careers at Quantum - Join Our Team"
        />
        <meta
          name="twitter:description"
          content="Explore career opportunities at Quantum. Discover our culture, values, and how you can be a part of our innovative team."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/company/career/assets/career-main.jpg"
        />
      </Helmet>
      <div className="about">
        <section className="section1 container">
          <div className="section-about-heading animate__animated animate__slideInUp">
            <span> Join Us and Grow Together</span>
          </div>
          <div className="section-detail animate__animated animate__slideInUp">
            <span>
              Be a part of a dynamic team where innovation meets opportunity.
              Work with us to shape your future, contribute to impactful
              projects, and thrive in a culture that values growth and
              creativity.
            </span>
          </div>
          <div className="section-image animate__animated animate__slideInUp">
            <img
              src={CareerMain}
              alt={"header-section-image"}
              height={"100%"}
              width={"100%"}
            />
          </div>
        </section>
        <section className="section-about-3-outer">
          <div className="section-about-3 container">
            <div className="left">
              <span className="left-text">Why Join Us?</span>
            </div>
            <div className="right d-flex align-items-center">
              <span className="right-text">
                Our people are what makes us special. Each team member brings
                unique skills and ideas, helping us grow together. We focus on
                valuing everyone and fostering teamwork, so we can tackle
                challenges and celebrate successes as one. Join us, where every
                person matters!
              </span>
            </div>
          </div>
        </section>
        <CurrentOpenings
          titleText={"Current Openings"}
          currentComponentData={currentComponentData}
        />
        <section className="last-section container">
          <div className="section-opening-heading text-center">
            <span>Do you want to work with us? Please write to us.</span>
          </div>
          <div className="section-opening-subheading text-center">
            <span>hr@qitsolution.co.in</span>
          </div>
        </section>
      </div>
    </>
  );
};

export default CareerPage;
