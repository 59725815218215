import React from "react";
import { SapHeroSection } from "../../../components/sap-pages-components/sap-hero-section/SapHeroSection";
import HeroImg from "../../../assets/svg/SapAnalytics/HeroImg.svg";
import { IndustryCarousel } from "../../../components/IndustriesCarousel/IndustriesCarousel";
import F1 from "../../../assets/svg/SapAnalytics/F1.svg";
import F2 from "../../../assets/svg/SapAnalytics/F2.svg";
import F3 from "../../../assets/svg/SapAnalytics/F3.svg";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import { Helmet } from "react-helmet-async";

const SapAnalytics = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "Deliver mission-critical analytics",
      text1:
        "Accelerate time to insight with pre-built dashboards and reports tailored to your industry, covering everything from spend management to workforce capital.",
      text2:
        "Access insights seamlessly across your data landscape by integrating with SAP Datasphere and SAP S/4HANA for real-time financial visibility.",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Infuse trusted AI",
      text1:
        "Leverage trusted AI, like the Joule copilot, to enhance decision-making through efficient analytics and scenario modeling. Empower users to gain instant insights by querying data in natural language.",
      text2:
        "Simplify complex tasks & automate workflows using generative AI to enrich data models with customised calculations.",
      flexDirection: "row-reverse",
    },
    {
      image: F3,
      cardTitle: "Transform enterprise planning",
      text1:
        "Utilize SAP Analytics Cloud to run data-driven risk scenario simulations and anticipate potential disruptions. Integrate data management and analytics into one tool for cross-organizational planning, breaking down departmental siloes",
      text2:
        "Implement xP&A by merging transactions and analytics across all business lines with SAP S/4HANA, enhancing decision-making and agility.",
      flexDirection: "row",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          SAP Analytics Cloud - Unlock Data-Driven Insights & AI-Powered
          Analytics
        </title>
        <meta
          name="description"
          content="Leverage SAP Analytics Cloud for real-time insights, AI-powered decision-making, and enterprise planning. Optimize business operations with predictive analytics and seamless SAP integration."
        />
        <meta
          name="keywords"
          content="SAP Analytics Cloud, SAP S/4HANA, Business Intelligence, Predictive Analytics, AI in SAP, Enterprise Planning, Data Visualization, SAP Datasphere, xP&A, Joule AI"
        />
        <meta
          property="og:title"
          content="SAP Analytics Cloud - Unlock Data-Driven Brilliance"
        />
        <meta
          property="og:description"
          content="SAP Analytics Cloud delivers real-time insights and predictive analytics, driving smarter decisions and fostering innovation across businesses."
        />
        <meta property="og:image" content={`${HeroImg}`} />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/solution/sap-Analytics"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="SAP Analytics Cloud - Unlock Data-Driven Brilliance"
        />
        <meta
          name="twitter:description"
          content="Drive business growth with SAP Analytics Cloud’s real-time insights, AI-powered analytics, and seamless integration with SAP S/4HANA."
        />
        <meta name="twitter:image" content={`${HeroImg}`} />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div className="saps4hana">
        <SapHeroSection
          capText={"SAP Analytics Cloud: The Oracle of Insights!"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText={
            "SAP Analytics Cloud: Unlocking Unmatched Data-Driven Brilliance."
          }
          detailsText="SAP Analytics Cloud delivers real-time insights and predictive analytics, driving smarter, data-driven decisions and fostering innovation across your business."
          image={HeroImg}
        />
        <section className="hero-section-2">
          <p>
            SAP Analytics Cloud is a complete solution for{" "}
            <b>analytics and planning </b>that is designed to unlock the full
            potential of<b> all your investments </b>in mission-critical
            business applications and most valuable data sources.
          </p>
        </section>
        <IndustryCarousel />
        <SapFeatureSection
          featureData={featureData}
          title={"Features that make work fun—business grows faster!"}
        />
      </div>
    </>
  );
};
export default SapAnalytics;
