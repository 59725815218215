import React from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./PrivacyPolicyPage.css";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Quantum</title>
        <meta
          name="description"
          content="Learn about our privacy policy and how we handle your data responsibly."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Quantum, Data Protection"
        />
      </Helmet>
      <section className="privacy-main">
        <div className="privacy-head-main container">
          <div className="hero-title animate__animated animate__slideInUp">
            <span>Privacy Policy</span>
          </div>
          <div className="hero-detail animate__animated animate__slideInUp">
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use, and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. We use Your Personal data to provide and improve
              the Service. By using the Service, You agree to the collection and
              use of information in accordance with this Privacy Policy.
            </p>
            <br />
            <p>Last updated: March 19, 2025</p>
          </div>
        </div>
        <PrivacyPolicyContent />
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
