import React from "react";
import { SapHeroSection } from "../../../components/sap-pages-components/sap-hero-section/SapHeroSection";
import HeroImg from "../../../assets/svg/sapS4hana/HeroImg.svg";
import { IndustryCarousel } from "../../../components/IndustriesCarousel/IndustriesCarousel";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import F1 from "../../../assets/svg/sapS4hana/F1.svg";
import F2 from "../../../assets/svg/sapS4hana/F2.svg";
import F3 from "../../../assets/svg/sapS4hana/F3.svg";
import F4 from "../../../assets/svg/sapS4hana/F4.svg";
import { SapVeriticalCorosal } from "../../../components/sap-pages-components/sap-vertical-corosal/SapVerticalCorosal";
import V1 from "../../../assets/svg/sapS4hana/V1.svg";
import V2 from "../../../assets/svg/sapS4hana/V2.svg";
import { Helmet } from "react-helmet-async";

// Renaming the component to avoid conflict with the import
const SapS4HanaPage = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "A database for any workload",
      text1:
        "The powerful multi-model engine allows you to process, store, and analyze diverse data types—relational, geospatial, graph, JSON, and vector—in a flexible database. ",
      text2:
        "Simplify integration with direct access to SAP business application data, enabling seamless querying and blending.",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Real-Time Analytics",
      text1:
        "Gain immediate insights and analytics for informed, data-driven decision-making that propels your business forward. With real-time data access, you can monitor key performance indicators (KPIs) and identify trends as they happen.",
      text2:
        "This capability enables swift responses to market changes and empowers teams to collaborate effectively.",
      flexDirection: "row-reverse",
    },
    {
      image: F3,
      cardTitle: "Smart data apps that leverage your insights.",
      text1:
        "Automated decision-making leverages machine learning and generative AI to enhance insights and optimize choices. Context-aware outputs provide real-time insights by securely connecting systems, reducing errors.",
      text2:
        "Business-ready GenAI enhances applications to effortlessly transform customer experiences and streamline operations, driving efficiency and innovation.",
      flexDirection: "row",
    },
    {
      image: F4,
      cardTitle: "Security, Compliance, and Scalability",
      text1:
        "SAP S/4HANA expertly manages technical operations, including backup, disaster recovery, system maintenance, and data protection.",
      text2:
        "Its scalable architecture adapts to your business growth while maintaining robust security and compliance with regulations.",
      flexDirection: "row-reverse",
    },
  ];
  const sections = [
    {
      id: "top",
      title: "From Challenges to Success",
      description:
        "Overcoming challenges, we implemented SAP S/4 to transform operations",
    },
    {
      id: "bottom",
      title: "Driving Growth with SAP S/4",
      description:
        "Unlocking new growth opportunities through the power of SAP S/4",
    },
  ];
  return (
    <>
      <Helmet>
        <title>SAP S/4HANA - Next-Gen ERP for Business Innovation</title>
        <meta
          name="description"
          content="Unlock real-time insights and streamline business operations with SAP S/4HANA. Enhance decision-making, automate workflows, and drive digital transformation with next-gen ERP."
        />
        <meta
          name="keywords"
          content="SAP S/4HANA, SAP ERP, Next-Gen ERP, Real-Time Analytics, SAP Business Suite, ERP System, Cloud ERP, SAP Digital Transformation, Business Process Automation, AI-Powered ERP"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="SAP S/4HANA - Next-Gen ERP for Business Innovation"
        />
        <meta
          property="og:description"
          content="SAP S/4HANA delivers real-time analytics, AI-driven insights, and a powerful in-memory database to streamline enterprise operations."
        />
        <meta property="og:image" content={`${HeroImg}`} />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/solution/sap-s4-hana"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="SAP S/4HANA - Next-Gen ERP for Business Innovation"
        />
        <meta
          name="twitter:description"
          content="Transform your business with SAP S/4HANA—a next-gen ERP suite for real-time operations, AI-driven insights, and enterprise automation."
        />
        <meta name="twitter:image" content={`${HeroImg}`} />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div className="ypp">
        <SapHeroSection
          capText={"SAP S/4HANA: The Wizard of Efficiency!!"}
          gradientColor={"linear-gradient(#eff8ff, #f1f5f900)0"}
          titleText="SAP S/4HANA: Turning Your Business into an Innovation Powerhouse!"
          detailsText="SAP S/4HANA is a next-gen ERP suite for real-time operations and analytics, streamlining processes and enhancing decision-making with its powerful in-memory database ."
          image={HeroImg}
        />
        <section className="hero-section-2">
          <p>
            An affordable <b>ERP solution </b>designed to help businesses manage
            various operations, including{" "}
            <b>
              accounting, financial, purchasing, inventory, sales, customer
              relationships,
            </b>{" "}
            and reporting analytics.
          </p>
        </section>
        <IndustryCarousel />
        <SapFeatureSection
          featureData={featureData}
          title={"Features that make work fun—business grows faster!"}
        />
        <SapVeriticalCorosal
          backgroundColor={"#edf8ff99"}
          buttonText={"Book a demo"}
          sections={sections}
          V1={V1}
          V2={V2}
          headerText={"SAP S/4 Implementation"}
          detailText={
            "Achieving a seamless and successful SAP implementation for optimized business performance."
          }
        />
      </div>
    </>
  );
};
export default SapS4HanaPage;
