import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import F1 from "../../../assets/svg/BapB1Up/F1.svg";
import F2 from "../../../assets/svg/BapB1Up/F2.svg";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import { StrategiesComponent } from "../../../components/Services/strategies-component/Strategies";
import { Helmet } from "react-helmet-async";

const SapB1Up = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "Flexible Configuration Options",
      text1:
        "The package offers intuitive configuration tools that allow users to modify existing processes and workflows easily, ensuring the ERP system aligns seamlessly with their specific operational requirements.",
      text2:
        "Enhances user experience by streamlining everyday tasks and ensuring that the system reflects the organization's specific needs, ultimately driving efficiency and productivity.",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Customisable Dashboards",
      text1:
        "Users create personalised views of relevant metrics and KPIs. This feature enhances decision-making by providing quick access to tailored data, ultimately driving productivity and aligning efforts with organisational goals.",
      text2:
        "Team members can align on key objectives, track progress, and identify areas for improvement together.",
      flexDirection: "row-reverse",
    },
  ];

  const strategiesComponentData = [
    {
      headerText: "Make Smarter Choices",
      detailText:
        "B1UP empowers SAP B1 users to access data in innovative ways, easing resource strain and enhancing decision-making.",
    },
    {
      headerText: "Streamline Your Business",
      detailText:
        "SAP B1 processes to match your work style, boosting productivity and enabling greater achievements.",
    },
    {
      headerText: "No Coding Needed",
      detailText:
        "SAP B1 users can now enjoy customizations that were once reserved for SAP development experts.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          SAP B1 Usability Package - Simplify & Customize SAP Business One
        </title>
        <meta
          name="description"
          content="Enhance your SAP Business One experience with the B1 Usability Package. Customize dashboards, streamline workflows, and boost productivity with a no-code solution."
        />
        <meta
          name="keywords"
          content="SAP B1 Usability Package, SAP B1UP, SAP Business One Customization, No-Code SAP Solution, ERP Optimization, SAP B1 Dashboards, Business Workflow Automation, SAP B1 Productivity Tools"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="SAP B1 Usability Package - No-Code Customization for SAP B1"
        />
        <meta
          property="og:description"
          content="Enhance SAP Business One usability with the B1 Usability Package—custom dashboards, automation, and intuitive configurations without coding."
        />
        <meta property="og:image" content={`${F2}`} />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/solution/sap-b1-up"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="SAP B1 Usability Package - No-Code Customization for SAP B1"
        />
        <meta
          name="twitter:description"
          content="Make SAP Business One easier with the B1 Usability Package—custom workflows, dashboards, and automation with zero coding required."
        />
        <meta name="twitter:image" content={`${F2}`} />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div className="Sapb1up">
        <CloudHeroSection
          background={"#FFF7EECC"}
          capText={"SAP Usability Package: Simplify Your Workflow"}
          gradientColor={"linear-gradient(#FFF3E6CC, #F1F5F900)0"}
          titleText="Make SAP Business One easier to use with a No-Code Solution! - B1 Usability Package"
          detailsText="The B1 Usability Package enhances user experience by providing customizable features and streamlined workflows tailored to individual preferences.  B1 Usability Package customises SAP Business One to align with your unique workflows and requirements. It streamlines processes, automates tasks and improves the overall user experience."
        />
        <SapFeatureSection featureData={featureData} />
        <StrategiesComponent
          strategiesComponentData={strategiesComponentData}
          titleText={"B1 Usability Package customizes SAP B1"}
        />
      </div>
    </>
  );
};
export default SapB1Up;
