import Solution from "../../../components/Platy/Solution";
import "./Platy.css";
import ProductFeature from "../../../components/ProductFeature/ProductFeature";
import BooknowComponent from "../../../components/BookNowComponent/BooknowComponent";
import F1 from "../../../assets/svg/FeatureBox/F1.svg";
import F2 from "../../../assets/svg/FeatureBox/F2.svg";
import F3 from "../../../assets/svg/FeatureBox/F3.svg";
import F4 from "../../../assets/svg/FeatureBox/F4.svg";
import F5 from "../../../assets/svg/FeatureBox/F5.svg";
import F6 from "../../../assets/svg/FeatureBox/F6.svg";
import F7 from "../../../assets/svg/FeatureBox/F7.svg";
import BBIC1 from "../../../assets/svg/BooknowBox/BBIC1.svg";
import BBIC2 from "../../../assets/svg/BooknowBox/BBIC2.svg";
import BBIC3 from "../../../assets/svg/BooknowBox/BBIC3.svg";
import HeroComponent from "../../../components/Hero/HeroComponent";
import PlatyHeroImg from "../../../assets/svg/Platy/heroImg.svg";
import S2 from "../../../assets/svg/Platy/S2.svg";
import ProductDescription from "../../../components/ProductDescription/ProductDescription";
import { Helmet } from "react-helmet-async";

export default function Home() {
  const productFeatureData = [
    {
      image: F1,
      cardHeader: "SAP B1 Integration",
      cardDesc:
        "Automatically submits approved entries to SAP B1 and updates records in real-time, eliminating manual data entry and ensuring accuracy.",
    },

    {
      image: F2,
      cardHeader: "Authenticity Verification",
      cardDesc:
        "Customers and vendors enter GST-verified details and upload a PAN card photo to prevent unauthorized registrations. Data is auto-filled for accuracy.",
    },
    {
      image: F3,
      cardHeader: "Client Portal",
      cardDesc:
        "Approved customers and vendors get credentials for their secure portal, and a registration link is available for easy self-sign-up.",
    },
    {
      image: F4,
      cardHeader: "User Management",
      cardDesc:
        "Create multiple users with customizable roles and authorizations. Manage permissions and access levels to ensure secure and efficient platform use.",
    },
  ];
  const productionFeatureSamll = [
    {
      image: F5,
      cardHeader: "Status Management",
      cardDesc:
        "Tracks entries as Pending, Approved, or Rejected, with required remarks for rejections.",
    },
    {
      image: F6,
      cardHeader: "Live Support",
      cardDesc:
        "Provides real-time help for queries or issues, ensuring prompt resolutions.",
    },
    {
      image: F7,
      cardHeader: "QR Code Status Tracking",
      cardDesc:
        "Users check their approval status via QR code, with updates sent by email.",
    },
  ];
  const bookNowData = [
    {
      image: BBIC1,
      text1: "5x",
      text2: "Cost Saving",
    },
    {
      image: BBIC2,
      text1: "60%",
      text2: "Free trial",
    },
    {
      image: BBIC3,
      text1: "20k+",
      text2: "Clients",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Vendor & Customer Management Solution - Quantum</title>
        <meta
          name="description"
          content="Streamline vendor and customer management with Platy, an SAP-integrated solution that ensures seamless verification, user management, and secure transactions."
        />
        <meta
          name="keywords"
          content="Vendor Management, Customer Management, SAP B1 Integration, Business Relationships, Client Portal, Secure Transactions, Quantum Platy"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="Vendor & Customer Management Solution - Quantum"
        />
        <meta
          property="og:description"
          content="Streamline vendor and customer management with Platy, an SAP-integrated solution that ensures seamless verification, user management, and secure transactions."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/product/platy/assets/platy-hero.jpg"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/product/platy"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Vendor & Customer Management Solution - Quantum"
        />
        <meta
          name="twitter:description"
          content="Streamline vendor and customer management with Platy, an SAP-integrated solution that ensures seamless verification, user management, and secure transactions."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/product/platy/assets/platy-hero.jpg"
        />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div id="Platy">
        <HeroComponent
          primaryGradiant={"#F3FAF8"}
          secondaryGradiant={"#F1F5F900"}
          heroHeader={`Efficient Management for
Vendors & Customers`}
          heroDetail={
            "Our software, Platy – Integrated Vendor & Customer Management Solutions provide a centralised and intuitive platform to manage, track, and nurture your critical business relationships, driving operational efficiency and sustainable growth."
          }
          heroImage={PlatyHeroImg}
        />
        <ProductDescription
          ProductName={"Platy"}
          TextColor={"#318280"}
          Discription={
            "A Vendor & Customer Management Solution, fully integrated with SAP Business One, provides a unified platform to manage, track, and enhance your business relationships. "
          }
          image={S2}
        />
        {/* <Feature /> */}
        <ProductFeature
          productFeatureData={productFeatureData}
          productionFeatureSamll={productionFeatureSamll}
        />
        <Solution />
        {/* <Booknow /> */}
        <BooknowComponent
          headingText={"Platy grows with your business"}
          bookNowData={bookNowData}
          backgroundColor={"#f3faf8"}
          backGroundGradient={"linear-gradient(360deg, #318281cc, #318280"}
          productname={"Platy"}
        />
      </div>
    </>
  );
}
