import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import F1 from "../../../assets/svg/powerbidashboard/F1.svg";
import F2 from "../../../assets/svg/powerbidashboard/F2.svg";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import { StrategiesComponent } from "../../../components/Services/strategies-component/Strategies";
import { Helmet } from "react-helmet-async";

const PowerBiDashBoard = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "Bring all Data Together",
      text1:
        "OneLake simplifies dataset creation from various sources, consolidating relevant data into a centralised hub. This ensures a unified source of truth, enhancing data integrity and accuracy across your organisation.",
      text2:
        "Empowers teams to access consistent insights, streamline workflows, and optimise reporting processes, leading to improved decision-making and operational efficiency..",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Turn Insights into Impacts",
      text1:
        "Enable users to make informed decisions by integrating insights into their daily applications. This seamless access to vital information fosters a data-driven culture, enhancing productivity and driving meaningful results.",
      text2:
        "Quickly address challenges and seize opportunities, enabling informed decision-making that drives growth .",
      flexDirection: "row-reverse",
    },
  ];
  const strategiesComponentData = [
    {
      headerText: "Centralized Data Source",
      detailText:
        "Unify all diverse data sources within a single environment to form a reliable and governed source of truth for information.",
    },
    {
      headerText: "Integrate Enterprise Scale ",
      detailText:
        "Merge enterprise-level capabilities and self-service BI to foster insights and innovation throughout your organization.",
    },
    {
      headerText: "Integrate Data Experiences ",
      detailText:
        "Connect insights to decisions with seamless integration across the everyday apps people rely on.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Power BI Dashboards - Data-Driven Insights for Smarter Decisions
        </title>
        <meta
          name="description"
          content="Unlock powerful insights with Power BI dashboards. Visualize, analyze, and transform data into actionable intelligence to drive business growth and efficiency."
        />
        <meta
          name="keywords"
          content="Power BI, Business Intelligence, Data Visualization, Power BI Dashboards, Analytics, Data Insights, Interactive Reports, Microsoft Power BI, Data-Driven Decisions"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="Power BI Dashboards - Data-Driven Insights for Smarter Decisions"
        />
        <meta
          property="og:description"
          content="Unlock powerful insights with Power BI dashboards. Visualize, analyze, and transform data into actionable intelligence to drive business growth and efficiency."
        />
        <meta property="og:image" content={`${F2}`} />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/solution/power-bi-dashboard"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Power BI Dashboards - Data-Driven Insights for Smarter Decisions"
        />
        <meta
          name="twitter:description"
          content="Unlock powerful insights with Power BI dashboards. Visualize, analyze, and transform data into actionable intelligence to drive business growth and efficiency."
        />
        <meta name="twitter:image" content={`${F2}`} />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <CloudHeroSection
        background={"#FFF7EECC"}
        capText={"Power BI Dashboards: Insights at Your Fingertips"}
        gradientColor={"linear-gradient(#FFF3E6CC, #F1F5F900)0"}
        titleText="Unlock Powerful Insights: The Power of Data at Your Fingertips with Power BI!"
        detailsText="Power BI dashboards offer a dynamic and interactive way to visualize data, turning complex information into clear, actionable insights. With customizable features, users can personalize dashboards to meet their specific needs, enabling efficient decision-making across all levels of the organization. Power BI empowers businesses to monitor performance and drive growth effectively."
      />
      <SapFeatureSection featureData={featureData} />
      <StrategiesComponent
        strategiesComponentData={strategiesComponentData}
        titleText={"Discover the Power of Power BI"}
      />
    </>
  );
};
export default PowerBiDashBoard;
