import React from "react";
import headerImg from "../../../assets/svg/about/Header-image.svg";
import "./about.css";
import { SapVeriticalCorosal } from "../../../components/sap-pages-components/sap-vertical-corosal/SapVerticalCorosal";
import { AboutInnovative, AboutSuccess } from "../../../assets";
import Solution from "../../../components/Home/Solution";
import "animate.css";
import { Helmet } from "react-helmet-async";

const sections = [
  {
    id: "top",
    title: "Innovating for a Smarter Future",
    description:
      "We create IT solutions that boost efficiency and business growth.",
  },
  {
    id: "bottom",
    title: "Success with Technology",
    description: "We harness technology to drive your success and growth.",
  },
];
const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Quantum</title>
        <meta
          name="description"
          content="Learn more about Quantum, our mission, values, and commitment to innovation. Discover what makes us unique."
        />
        <meta
          name="keywords"
          content="About Quantum, Our Mission, Company Values, Who We Are, Innovation, SAP, SAP Business One, business management, SAP Partner "
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content="About Us - Quantum" />
        <meta
          property="og:description"
          content="Learn more about Quantum, our mission, values, and commitment to innovation. Discover what makes us unique."
        />
        <meta
          property="og:image"
          content="https://qitsolution.co.in/company/about/assets/svg/about/Header-image.svg"
        />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/company/about"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Quantum" />
        <meta
          name="twitter:description"
          content="Learn more about Quantum, our mission, values, and commitment to innovation. Discover what makes us unique."
        />
        <meta
          name="twitter:image"
          content="https://qitsolution.co.in/company/about/assets/svg/about/Header-image.svg"
        />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>

      <div className="about">
        <section className="section1 container">
          <div className="section-about-heading animate__animated animate__slideInUp">
            <span> From Dreams to Reality!</span>
          </div>
          <div className="section-detail animate__animated animate__slideInUp">
            <span>
              We had a simple idea: to make running a business easier. That's
              how our journey into the world of SAP Business One began. We
              believe that every business deserves the right tools to thrive, no
              matter its size. With a team that feels more like family, we're
              passionate about helping you navigate the complexities of business
              management. We’re not just here to provide solutions; we’re here
              to understand your unique challenges and work together to unlock
              your full potential.
            </span>
          </div>
          <div className="section-image animate__animated animate__slideInUp">
            <img
              src={headerImg}
              alt={"header-section-image"}
              height={"100%"}
              width={"100%"}
              loading="lazy"
            />
          </div>
        </section>

        <section className="section-solution-outer container">
          <Solution />
        </section>
        <section className="section-about-3-outer">
          <div className="section-about-3 container">
            <div className="left">
              <span className="left-text">
                People are <br /> our strength!
              </span>
            </div>
            <div className="right">
              <span className="right-text">
                Our people are what makes us special. Each team member brings
                unique skills and ideas, helping us grow together. We focus on
                valuing everyone and fostering teamwork, so we can tackle
                challenges and celebrate successes as one. Join us, where every
                person matters!
              </span>
            </div>
          </div>
        </section>

        <SapVeriticalCorosal
          sections={sections}
          V1={AboutInnovative}
          V2={AboutSuccess}
          headerText={"Our Mission"}
          detailText={
            "To revolutionize businesses with innovative technology solutions that drive efficiency and growth."
          }
        />

        <section className="section-connect container">
          <div className="connect-info">
            <div className="connect-info-text-section text-center">
              <div className="connect-info-text1">
                Let’s do business together, benefiting both.
              </div>
              <div className="connect-info-text2">
                Chalo vyapar saathe kariye, aapne banne ne samruddhi, kariye!
              </div>
            </div>
            <div className="connect-info-btn">
              <button>Heya, Connect?</button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default About;
