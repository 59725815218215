import React from "react";
import { CloudHeroSection } from "../../../components/Services/cloud-infra-header/CloudInfraHero";
import { StrategiesComponent } from "../../../components/Services/strategies-component/Strategies";
import F1 from "../../../assets/svg/sapAddOns/F1.svg";
import F2 from "../../../assets/svg/sapAddOns/F2.svg";
import { SapFeatureSection } from "../../../components/sap-pages-components/sap-feature-section/SapFeatureSection";
import { Helmet } from "react-helmet-async";

const SapAddOns = () => {
  const featureData = [
    {
      image: F1,
      cardTitle: "E-Invoice & E-Way Bill",
      text1:
        "To modernize business practices, e-Invoicing and e-way billing have become essential. The electronic invoicing process has rapidly reduced reliance on traditional paper-based invoicing, benefiting both corporate and public sector organizations.",
      text2:
        "This shift has particularly captured the interest of tax departments, which are increasingly leveraging the advantages of e-invoicing services.",
      flexDirection: "row",
    },
    {
      image: F2,
      cardTitle: "Digital Signature Solution",
      text1:
        "Go digital with Quantum's PDF-Signer, an innovative online application that transforms document workflows by simplifying contract management and eliminating the need for printing and scanning.",
      text2:
        "Experience the future of secure and efficient document signing with our cutting-edge solution.",
      flexDirection: "row-reverse",
    },
  ];
  const strategiesComponentData = [
    {
      headerText: "WhatsApp Web Integration",
      detailText:
        "Enhance customer communication by integrating WhatsApp Web with SAP for real-time messaging and efficient client interaction management",
    },
    {
      headerText: "CRM Integration",
      detailText:
        "Improve sales, marketing, and support processes for better efficiency, enhanced customer satisfaction, and sustainable overall business growth.",
    },
    {
      headerText: "GSTR - 2A/2B",
      detailText:
        "Improve tax management, minimize manual effort, and ensure GST compliance for greater accuracy and efficiency.",
    },
  ];
  const strategiesComponentData2 = [
    {
      headerText: "Job Work/ Sub Contracting",
      detailText:
        "Efficiently manage job work and subcontracting with SAP Business One, allowing seamless tracking of external processes and vendor relationships.",
    },
    {
      headerText: "Machine Maintenance",
      detailText:
        "Enhance equipment reliability, reduce downtime, and ensure compliance with maintenance standards for improved operational efficiency.",
    },
    {
      headerText: "Gate In & Out ",
      detailText:
        "Efficiently manage inventory with the Gate In and Gate Out functionalities in SAP Business One, enabling accurate tracking of stock movements.",
    },
    {
      headerText: "Quality Control ",
      detailText:
        "Our add-on leverages the flexibility of SAP Business One and utilizes the data already in the system, enabling seamless quality management assessments.",
    },
    {
      headerText: "Inventory Production Approval",
      detailText:
        "It provides real-time inventory visibility, enabling accurate records, optimized resource allocation, and improved operational efficiency.",
    },
    {
      headerText: "Exim (Export-Import)",
      detailText:
        "EXIM in SAP streamlines the management of export and import operations, facilitating customs documentation and compliance with trade regulations.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>SAP Add-Ons - Enhance & Optimize Your Business Operations</title>
        <meta
          name="description"
          content="Boost your SAP system with powerful add-ons for e-invoicing, digital signatures, CRM integration, machine maintenance, quality control, and more."
        />
        <meta
          name="keywords"
          content="SAP Add-Ons, SAP Business One, SAP Integration, E-Invoicing, Digital Signature, CRM Integration, Quality Control, Job Work, Machine Maintenance, Inventory Management, Export-Import SAP"
        />
        {/* <!-- Open Graph Meta Tags --> */}
        <meta
          property="og:title"
          content="SAP Add-Ons - Enhance & Optimize Your Business Operations"
        />
        <meta
          property="og:description"
          content="Boost your SAP system with powerful add-ons for e-invoicing, digital signatures, CRM integration, machine maintenance, quality control, and more."
        />
        <meta property="og:image" content={`${F1}`} />
        <meta
          property="og:url"
          content="https://qitsolution.co.in/solution/sap-add-on"
        />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="SAP Add-Ons - Enhance & Optimize Your Business Operations"
        />
        <meta
          name="twitter:description"
          content="Boost your SAP system with powerful add-ons for e-invoicing, digital signatures, CRM integration, machine maintenance, quality control, and more."
        />
        <meta name="twitter:image" content={`${F1}`} />
        <meta name="twitter:site" content="@Qitsolution54" />
      </Helmet>
      <div className="SapAddOns">
        <CloudHeroSection
          background={"#FFF7EECC"}
          capText={"SAP Add-Ons: Enhance Business Operations Effortlessly!"}
          gradientColor={"linear-gradient(#FFF3E6CC, #F1F5F900)0"}
          titleText="Maximise Your SAP Experience with Innovative Add-Ons for Enhanced Functionality."
          detailsText="SAP add-ons enhance your existing SAP systems by providing customised functionalities that streamline operations and boost efficiency. These flexible solutions integrate seamlessly, allowing businesses to adapt their systems to unique requirements and industry demands. With SAP add-ons, you can unlock the full potential of your SAP environment, driving growth and improving performance."
        />
        <StrategiesComponent
          strategiesComponentData={strategiesComponentData}
        />
        <SapFeatureSection featureData={featureData} />
        <StrategiesComponent
          strategiesComponentData={strategiesComponentData2}
          titleText={"Our Other Add-Ons"}
        />
      </div>
    </>
  );
};
export default SapAddOns;
